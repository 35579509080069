﻿// Libraries
import m, { request } from 'mithril'
import { find, map, groupBy, filter } from 'lodash'

// Models
import User from '../models/User'
import Film from '../models/Film'
import VoterFilm from '../models/VoterFilm'

// Components
import { card } from 'bootstrap-mithril'
import formGroup from '../components/formGroup'
import saveBtn from '../components/saveBtn'
import loading from '../components/loading'
import icon from '../components/icon'
import { actionbar } from '../components/actionbar'
import notifications from '../components/notifications'
import { DateTime } from 'luxon'

var pageFilm = {}

pageFilm.oninit = (vnode) => {
	if (vnode.attrs.user().isMember) {
		if (!User.subgroups()) User.getSubgroups(vnode.attrs.user().id)
		Film.get(vnode.attrs.id)
		VoterFilm.getByFilmId(vnode.attrs.id)
		vnode.state.screenRequest = false
		vnode.state.requesting = false
		m.request({
			method: 'GET',
			url: `https://bifa-api.azurewebsites.net/voting/api/films/${vnode.attrs.id}/request-screener`,
			config: (xhr) => {
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
			},
		})
			.then((req) => {
				vnode.state.screenRequest = req
			})
			.catch(() => {
				vnode.state.screenRequest = false
			})
	}
}

pageFilm.onupdate = (vnode) => {
	if (Film.current()) {
		document.title = `${Film.current().title} - Film - BIFA Voting`
	}
	vnode.attrs.unsavedState({ state: history.state, title: document.title, href: window.location.href })
	vnode.attrs.isUnsavedChanges(VoterFilm.hasChanges())
}

pageFilm.view = (vnode) => {
	return Film.current()
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: 'All Films',
							href: `/viewing/all`,
						},
						{
							text: 'Film: ' + Film.current().title,
						},
					],
					buttons: [
						m(
							'a.btn.btn-info',
							{
								href: '#',
								onclick: (e) => {
									e.preventDefault()
									history.back()
								},
							},
							'Back to films list'
						),
						m(saveBtn, { resource: VoterFilm, resourceParam: vnode.attrs.id }),
					],
				}),
				m('.container-fluid', [
					m('.row.main-content', [
						m('.col-md-6.col-lg-7', [
							m('.card.mb-3', [
								m('.row.no-gutters', [
									m('.col-md-12.col-lg-4.order-2.order-lg-1', [
										Film.current().posterUrl
											? m('img.card-img', {
													src: `${Film.current().posterUrl}?w=600&fm=jpg`,
											  })
											: m('svg.card-img-top', { 'text-anchor': 'middle', role: 'img' }, [
													m('rect', { width: '100%', height: '100%', fill: '#868e96' }),
													m('text', { x: '50%', y: '50%', fill: '#efefef', dy: '.3em' }, 'Poster coming soon'),
											  ]),
										m('.p-3', [
											m(formGroup, {
												label: 'Screening',
												isStatic: true,
												resource: Film,
												property: 'screeningInfo',
											}),
											Film.current().screenerDates &&
												m(formGroup, {
													label: 'Available from',
													isStatic: true,
													resource: Film,
													property: 'screenerDates',
												}),
											Film.current().screenerRestrictions &&
												m(formGroup, {
													label: 'Additional screener info',
													isStatic: true,
													resource: Film,
													property: 'screenerRestrictions',
												}),
											Film.current().screeningLink && Film.current().screeningLink.indexOf('http') === 0
												? m(formGroup, {
														label: 'Screening link',
														isStatic: true,
														resource: Film,
														link: { href: Film.current().screeningLink, target: '_blank' },
														property: 'screeningLink',
														value: ['Watch Now', m(icon, { iconName: 'external-link ml-2' })],
												  })
												: Film.current().screeningInfo.indexOf('Secure online screener sent directly to BIFA voters') >=
												  0
												? m(
														'button.btn.btn-info',
														{
															type: 'button',
															disabled: vnode.state.screenRequest || vnode.state.requesting ? 'disabled' : null,
															class: vnode.state.screenRequest || vnode.state.requesting ? 'disabled' : '',
															onclick: (e) => {
																if (!vnode.state.requesting) {
																	vnode.state.requesting = true
																	m.request({
																		method: 'POST',
																		url: `https://bifa-api.azurewebsites.net/voting/voterfilms/${vnode.attrs.id}/request-screener`,
																		config: (xhr) => {
																			xhr.setRequestHeader(
																				'Authorization',
																				`Bearer ${localStorage.getItem('accessToken')}`
																			)
																		},
																	}).then((data) => {
																		vnode.state.requesting = false
																		vnode.state.screenRequest = data
																		notifications.clear()
																		notifications.add({
																			title: 'Request for screener sent',
																			message: 'Thank you, your request has been sent.',
																			type: 'success',
																		})
																		;(e.currentTarget || e.target).innerText = 'Request sent'
																		;(e.currentTarget || e.target).disabled = true
																	})
																}
															},
														},
														vnode.state.screenRequest
															? `Screener Requested ${DateTime.fromISO(
																	vnode.state.screenRequest.requestDate
															  ).toLocaleString(DateTime.DATE_SHORT)}`
															: 'Request Screener'
												  )
												: null,
											vnode.state.screenRequest &&
												m(
													'p',
													"Your screener request has been received and will be passed to the film's distributor; please note it may take up to 5 working days for the screener to be delivered to you"
												),
											Film.current().screeningLinkPassword
												? m(formGroup, {
														label: 'Screening password',
														isStatic: true,
														resource: Film,
														property: 'screeningLinkPassword',
												  })
												: null,
										]),
									]),
									m('.col-md-12.col-lg-8.order-1.order-lg-2', [
										m('.card-body', [
											m('h3.card-title', Film.current().title),
											m.trust(Film.current().description),
											m('hr'),
											m('.row', [
												Film.current().runningTime
													? m('.col-md-12', [
															m(formGroup, {
																label: 'Running time (mins)',
																isStatic: true,
																resource: Film,
																property: 'runningTime',
															}),
													  ])
													: null,
											]),
										]),
									]),
								]),
							]),
							// Film.current().TrailerVimeoId || Film.current().TrailerYoutubeId
							// 	? m(card, {
							// 			header: 'Watch Trailer',
							// 			bodyClasses: ['p-0'],
							// 			body: [
							// 				m('.embed-responsive.embed-responsive-16by9', [
							// 					m('iframe.embed-responsive-item', {
							// 						allowfullscreen: true,
							// 						src: Film.current().TrailerVimeoId
							// 							? `https://vimeo.com/${Film.current().TrailerVimeoId}`
							// 							: `https://www.youtube.com/embed/${Film.current().TrailerYoutubeId}`,
							// 					}),
							// 				]),
							// 			],
							// 	  })
							// 	: null,
							m(card, {
								header: 'Entries',
								classes: ['card-sm'],
								bodyClasses: ['p-0'],
								body: [
									m('table.table.table-sm', [
										m('thead', [m('tr', [m('th', 'Category'), m('th', 'People'), m('th', 'Subgroup Status')])]),
										m('tbody', [
											map(
												groupBy(Film.current().entries, (a) => a.entryId),
												(entry) => {
													return m('tr', [
														m('td', entry[0].votingCategoryShortName),
														m(
															'td',
															entry[0].person
																? m('ul.list-unstyled', [
																		map(entry, (person) => {
																			return m('li.list-item', [
																				m(icon, { iconName: 'user mr-1' }),
																				person.person,
																				person.character ? ` (${person.character})` : null,
																			])
																		}),
																		entry[0].attachments.length
																			? map(entry[0].attachments, (file) => {
																					return m('li.list-item', [
																						m(
																							'a',
																							{
																								href: '',
																								onclick(e) {
																									e.preventDefault()
																									Film.downloadEntryAttachment(file.id, file.name)
																								},
																							},
																							[m(icon, { iconName: 'file mr-1' }), file.name]
																						),
																					])
																			  })
																			: null,
																		entry[0].statement
																			? m(
																					'button.btn.btn-sm.btn-secondary',
																					{
																						'data-toggle': 'modal',
																						'data-target': `#statement-${entry[0].entryId}`,
																					},
																					'Statement'
																			  )
																			: null,
																		entry[0].statement
																			? m(
																					'.modal.fade',
																					{
																						oncreate: (vnode) => {
																							$(vnode.dom).modal({
																								focus: false,
																								show: false,
																							})
																						},
																						onremove: (vnode) => {
																							$(vnode.dom).modal('dispose')
																						},
																						id: `statement-${entry[0].entryId}`,
																						role: 'dialog',
																						tabindex: -1,
																						'data-container': 'body',
																					},
																					[
																						m('.modal-dialog.modal-xl', [
																							m('.modal-content', [
																								m('.modal-header', [
																									m('h5', entry[0].votingCategoryShortName + ' Statement'),
																								]),
																								m('.modal-body', [
																									m.trust(
																										entry[0].statement.replace(
																											/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
																											'$1<br>$2'
																										)
																									),
																								]),
																							]),
																						]),
																					]
																			  )
																			: null,
																  ])
																: null
														),
														m('td', entry[0].viewerStatus ? entry[0].viewerStatus.Name : null),
													])
												}
											),
										]),
									]),
								],
							}),
						]),
						m('.col-md-6.col-lg-5', [
							VoterFilm.current()
								? m(card, {
										header: m('.d-flex.justify-content-between', [
											m('.c-title', 'Your feedback'),
											VoterFilm.current().isAssigned
												? m(icon, { iconName: 'bookmark-o', title: 'Assigned film' })
												: null,
										]),
										headerClasses: VoterFilm.current().isAssigned ? ['bg-success', 'text-white'] : null,
										body: [
											m('p', 'Please give your feedback to the film, by selecting your seen/conflicted status'),
											m('.s-ns-c', [
												m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
													m('input.custom-control-input', {
														type: 'radio',
														id: 'i-seen',
														name: 's-ns-c',
														checked: VoterFilm.current().isSeen,
														onchange: (e) => {
															VoterFilm.current().isSeen = (e.currentTarget || e.target).checked
															VoterFilm.save()
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-seen',
														},
														'Seen'
													),
												]),
												m('.custom-control.custom-switch.custom-control-inline', [
													m('input.custom-control-input', {
														type: 'radio',
														id: 'i-not-seen',
														name: 's-ns-c',
														checked: !VoterFilm.current().isSeen,
														onchange: (e) => {
															VoterFilm.current().isSeen = !(e.currentTarget || e.target).checked
															VoterFilm.save()
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-not-seen',
														},
														'Not Seen'
													),
												]),
												m('.custom-control.custom-switch.custom-control-inline', [
													m('input.custom-control-input.custom-control-input-danger', {
														type: 'checkbox',
														id: 'i-conflicted',
														name: 's-ns-c',
														checked: VoterFilm.current().isConflicted,
														onchange: (e) => {
															VoterFilm.current().isConflicted = (e.currentTarget || e.target).checked
															VoterFilm.save()
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-conflicted',
														},
														'Conflicted'
													),
												]),
											]),
											VoterFilm.current().isSeen && !VoterFilm.current().isConflicted
												? m('.feedback.mt-5.position-relative', [
														m('hr'),
														m(
															'p',
															'Please give your response to the categories this film has been entered for (rows with a green background indicate your subgroup):'
														),
														getFeebackTable(),
														m('hr'),
														m(formGroup, {
															label: 'Your comments',
															resource: VoterFilm,
															type: 'textarea',
															property: 'comments',
															rows: 6,
														}),
														m('small.form-text.text-muted', [
															m(icon, { iconName: 'info mr-2' }),
															'These are confidential and only visible to you, the chair of your sub-group and a BIFA admin',
														]),
														m(formGroup, {
															label: 'Keep comments anonymous',
															resource: VoterFilm,
															type: 'checkbox',
															property: 'isCommentsAnonymous',
														}),
														Film.current().entries[0].votingCategoryShortName === 'Short'
															? [
																	m('hr'),
																	m(formGroup, {
																		label: 'Feedback for entrants',
																		resource: VoterFilm,
																		type: 'textarea',
																		property: 'commentsForEntrant',
																		rows: 6,
																	}),
																	m('small.form-text.text-muted', [
																		m(icon, { iconName: 'info mr-2' }),
																		'All comments in this box will be anonymised',
																	]),
															  ]
															: null,
														VoterFilm.saving() &&
															m(
																'.loading-overlay.position-absolute',
																{
																	style:
																		'top:0;left:0;right:0;bottom:0;z-index:99;padding-top:3rem;background:rgba(255,255,255,.5)',
																},
																[m(loading)]
															),
												  ])
												: null,
										],
								  })
								: m(loading),
						]),
					]),
				]),
		  ]
		: m(loading)
}

function getFeedbackSwitch(entry, type, vsid, color) {
	return m('.custom-control.custom-switch.custom-control-inline.mr-0', [
		m('input.custom-control-input', {
			type: 'radio',
			className: `custom-control-input-${color}`,
			id: `entry-${type}-${entry[0].entryId}`,
			name: `entry-nacmtsats-${entry[0].entryId}`,
			checked: find(VoterFilm.current().voterEntries, (e) => {
				return e.entryId === entry[0].entryId && e.viewerStatusId === vsid
			}),
			onchange: (e) => {
				var v = find(VoterFilm.current().voterEntries, (e) => {
					return e.entryId === entry[0].entryId
				})
				if (v) v.viewerStatusId = vsid
				else VoterFilm.current().voterEntries.push({ entryId: entry[0].entryId, viewerStatusId: vsid })
				VoterFilm.save()
			},
		}),
		m(
			'label.custom-control-label',
			{
				for: `entry-${type}-${entry[0].entryId}`,
			},
			''
		),
	])
}

function getFeebackTable() {
	return m('table.table.table-sm', [
		m('thead', [
			m('tr', [
				m('th', 'Entry'),
				m('th.text-center', 'Not a contender'),
				m('th.text-center', 'More to see'),
				m('th.text-center', 'All to see'),
			]),
		]),
		m('tbody', [
			User.subgroups()
				? map(
						filter(
							groupBy(Film.current().entries, (a) => a.entryId),
							(e) => {
								return find(User.subgroups(), ['Id', e[0].subgroupId])
							}
						),
						(entry) => {
							return !entry[0].subgroupIsHidden
								? m('tr.table-success', [
										m('td', [
											m('.d-block', entry[0].votingCategoryShortName),
											entry[0].person
												? m('.text-muted.small', [
														map(entry, (person) => {
															return person.person + (person.character ? ` (${person.character})` : '')
														}).join(', '),
												  ])
												: null,
										]),
										m('td.text-center', [getFeedbackSwitch(entry, 'nac', 1, 'danger')]),
										m('td.text-center', [getFeedbackSwitch(entry, 'mts', 2, 'warning')]),
										m('td.text-center', [getFeedbackSwitch(entry, 'ats', 3, 'success')]),
								  ])
								: null
						}
				  )
				: null,
			User.subgroups()
				? map(
						filter(
							groupBy(Film.current().entries, (a) => a.entryId),
							(e) => {
								return !find(User.subgroups(), ['Id', e[0].subgroupId])
							}
						),
						(entry) => {
							return !entry[0].subgroupIsHidden
								? m('tr', [
										m('td', [
											m('.d-block', entry[0].votingCategoryShortName),
											entry[0].person
												? m('.text-muted.small', [
														map(entry, (person) => {
															return person.person + (person.character ? ` (${person.character})` : '')
														}).join(', '),
												  ])
												: null,
										]),
										m('td.text-center', [getFeedbackSwitch(entry, 'nac', 1, 'danger')]),
										m('td.text-center', [getFeedbackSwitch(entry, 'mts', 2, 'warning')]),
										m('td.text-center', [getFeedbackSwitch(entry, 'ats', 3, 'success')]),
								  ])
								: null
						}
				  )
				: null,
		]),
	])
}

export default pageFilm
